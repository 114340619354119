import { getSessionAndUserId } from "../services/InstancesServices/InstancesListService";
import { PAGE_INDEX_LIMIT } from "../utils/Emuns";

export const SEVERNAMETHREE = "http://192.168.88.216:1002";
// const SEVERNAME = "http://machines.bizgaze.com";
// export const SEVERNAME = "http://192.168.88.33:45457";
//export const SEVERNAME = "http://192.168.88.33:45455";
//export const SEVERNAME = "https://test.bizgaze.com:8443";
//export const SEVERNAME = "http://192.168.88.33:45458";
//export const SEVERNAME = "http://192.168.88.99:1003";
// export const SEVERNAME = 'http://192.168.89.103:5002';
// export const SEVERNAME = 'https://hsadmin.hireserver.com';
export const SEVERNAME = 'https://qa.hireserver.com'; //live
// export const SEVERNAME = 'https://qa.hireserver.com'; // dev 05-06-2023
//export const SEVERNAME = 'https://testhsadmin.hireserver.com'; 
// export const SEVERNAME = 'https://testadmin.hireserver.com';
//   export const SEVERNAME = process.env.REACT_APP_SERVERNAME;
//export const SEVERNAME = "http://192.168.88.99:1002";
//export const SEVERNAME = "http://192.168.89.28";
export const SEVERNAMETWO = "http://console.hireserver.com";

// export const LOCALHOST = "http://localhost:3088"
//export const LOCALHOST = "https://testadmin.hireserver.com";

export const SEVER_ILO = "https://ilo.hireserver.com";
export const SCRIPTS_SERVERNAME = 'https://scripts.hireserver.com';

export const INSTANCES_SSH_URL = `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/getsshkey`;

export function getViewBareMetal_IP_Name() {
    return SEVER_ILO;
}

export function getSignalRConnectionURL() {
    return `${SEVERNAME}/notificationhub`;
}

export function getLastNotifationURL(size = 20) {
    return `${SEVERNAME}/apis/v4/unibase/reminders/platform/getunreadreminders/ReminderType/6/PageSize/${size}`;
}
export function getLastNotifationAllURL(userId,page) {
// return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/searchscriptlogs/index/${page}/rows/${PAGE_INDEX_LIMIT}/scriptid/${scriptid}/searchbyname/${searchbyname}`;

    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getreminders/index/${page}/rows/${PAGE_INDEX_LIMIT}/userid/${userId}`;
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getreminders/userid/${userId}`;
}

export function getOrderRefIdURL(orderid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getreforderid/orderid/${orderid}`;
}


export function getUnReadNoticeURL() {
    return `${SEVERNAME}/apis/v4/unibase/reminders/platform/getunreadreminderscount`;
}

export function postMarkAsReadNoticeURL(rememberId) {
    return `${SEVERNAME}/apis/v4/unibase/reminders/platform/changereminderstatus/reminderid/${rememberId}/status/Read`;
}

export function postMarkSelectNotifsURL() {
    return `${SEVERNAME}/apis/v4/unibase/reminders/platform/MarkallUnreadreminders`;
}

export function getSEVERNAME() {
    return SEVERNAME;
}

export function getAssestsURL() {
    return `https://assets.bizgaze.com`;
}

export function getOtpValidURL() {
    return `${SEVERNAME}/hyperfusion/validateotp`;
}

export function getSendOTPURL() {
    return `${SEVERNAME}/account/sendotp`;
}

export function getDatabasesURL() {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getdatabases`;
}

export function getMarketPlacesURL() {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getapps`;
}

export function reSendOTPURL() {
    return `${SEVERNAME}/hyperfusion/resendotp`;
}

export function getOSMachinesURL() {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getos`;
}

export function getTenureInstanceURL(contactid, currenyid, blockid) {
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/getblokprice/blockid/${blockid}/contactid/${contactid}`;
    // return `${SEVERNAME}/apis/v4/bizgaze/hyperfusion/servers/gettenuretypes`;
}

export function instancesActionURL(machineid, updateToStageId) {
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/machineaction/machineid/${machineid}/statusid/${updateToStageId}/isreinstall/false`;
}

export function getLocationURL(blocks) {


    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getbrancheslist`
}

export function getAvailableInstancesURL(cores, blockidStage, userid, storageInfo) {
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/getserveravailability/noofcores/${cores}/blockid/${blockidStage}`
}

export function getInstancesProxmoxURL(id) {

    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/getvminfo/node/${id}`;
}

export function getInstanceDetailsURL(machineId) {

    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getinstancedetails/machineid/${machineId}`;
}

export function getGraphInstancesDetailsURL(vmid, node, clusterid) {
    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmoxdc/getrrddata/vmid/${vmid}/node/${node}/location/${clusterid}`;
    // return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmoxdc/getrrddata/vmid/${vmid}/node/${node}/location/${location}`;
}

export function getInstanceSnapShotURL(vmid, node, location) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/proxmoxdc/getsnapshotslist/node/${node}/vmid/${vmid}/location/${location}`;
}

export function postInstanceSnapShotRollBackURL(vmid, node, location, snapname) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/proxmoxdc/rollbacksnapshot/node/${node}/vmid/${vmid}/location/${location}/snapname/${snapname}`;

    // http://192.168.88.216:1002/apis/v4/hireserver/integrations/proxmoxdc/rollbacksnapshot/node/pve/vmid/111115/location/100012220000001/snapname/gg
}

export function instancesSnapShotRemoveURL(vmid, node, location, snapname) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/proxmoxdc/removesnapshot/node/${node}/vmid/${vmid}/snapname/${snapname}/location/${location}`
}

export function createInstancesShapShotURL(vmid, node, location) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/proxmoxdc/createsnapshot/node/${node}/vmid/${vmid}/location/${location}/frombody`;
}

export function getbackupjobsURL(location) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/proxmoxdc/getbackupjobs/location/${location}`
}

export function userSignUpURL() {
    return `${SEVERNAME}/hyperfusion/registerhyperfusionuser`;
    // return `${SEVERNAME}/Account/registerhyperfuisonuser`;
}

export function userLoginInURL() {
    // return `https://test.bizgaze.app:8443/account/login`;
    return `${SEVERNAME}/hyperfusion/hyperfusionuserlogin`;
}

export function createBackupInstanceURL(location, id) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/proxmoxdc/updatebackupjob/location/${location}/id/${id}/frombody`;
}

export function getBackupListURL(location) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/proxmoxdc/getbackuplist/node/pve/location/${location}`
}

export function getVmTaskHistory(vmid, node, location, since, start = 0, limit = 500) {
    // return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmoxdc/getvmtasks/vmid/${vmid}/node/${node}/location/${location}/since/${since}/start/${start}/limit/${limit}`;

    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmoxdc/getvmtasks/node/${node}/vmid/${vmid}/since/${since}/start/${start}/limit/${limit}/location/${location}`
    // return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmoxdc/getvmtasks/vmid/${vmid}/node/${node}/location/${location}/since/${since}/start/${start}/limit/${limit}`;
}

export function getInstanceBizgazeURL(userid) {
    // return `${SEVERNAME}/apis/v4/bizgaze/hyperfusion/servers/getmachines`;
    // return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getinstanceslist`;
    // return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getinstanceslist/userid/${userid}`

    // return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getinstanceslist/userid/${userid}` working

    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getinstancesandorders/userid/${userid}`;
}
export function getInstanceLeftOutBizgazeURL(userid) {

    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getpendinginstances/userid/${userid}`;
}

export function getServersInstancesURL() {
    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/getnodeinfo`;
}

export function createMachineInstanceFormURL() {
    return `${SEVERNAME}/apis/v4/unibase/platform/forms/savedynform/100011470001094`;
}

export function createMachineInstanceFormNEWURL() {
    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/restorebackup`;
}

export function createMachineInstanceBizgaze() {
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/createmachineorder`;
    // return `${SEVERNAME}/apis/v4/bizgaze/hyperfusion/servers/createmachineorder`;
    // return `${SEVERNAME}/apis/v4/bizgaze/hyperfusion/servers/createmachineorder`;
    // return `${SEVERNAME}/apis/v4/bizgaze/hyperfusion/servers/savemachine`;
}

export function getVMCreateCodeURL() {
    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/getnextid`
}

export function getListInstancesURL() {
    return `${SEVERNAME}/apis/v4/unibase/platform/analytics/reportinfo`;
}

export function getBareMetalListURL(userid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getbaremetallist/userid/${userid}`;
    // return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getbaremetallist`;
}

export function getBareMetalDetailsURL(machineid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getbaremetaldetails/machineid/${machineid}`
}

export function setCoresAfterCreateURL() {
    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/configvm`
}

export function twoStartURL(vmid, node, location) {
    // return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/startvm/vmid/${machineid}/node/${id}`;
    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmoxdc/startvm/vmid/${vmid}/node/${node}/location/${location}`
}

export function reInstallMachineUrl(machineid, osid, mpid) {
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/reinstallmachine/machineid/${machineid}/osid/${osid}/marketplaceid/${mpid}`
    // return `${SEVERNAME}/apis/v4/bizgaze/hyperfusion/servers/reinstallmachine/machineid/${machineid}/osid/${osid}`;
}

export function twoStopURL(vmid, node, location) {
    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmoxdc/stopvm/vmid/${vmid}/node/${node}/location/${location}`;
    // return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/stopvm/vmid/${machineid}/node/${id}`;
    // return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/stopvm/node/pve/vmid/${machineid}`;
}

export function twoRebootURL(vmid, location) {
    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmoxdc/rebootvm/vmid/${vmid}/location/${location}`;
    // return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/rebootvm/vmid/${machineid}/node/${id}`;
}

export function twodeleteURL(node, vmid, location) {
    // return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/deletevm/vmid/${machineid}/node/${id}`;

    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmoxdc/deletevm/node/${node}/vmid/${vmid}/location/${location}`

    // return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/deletevm/node/${id}/vmid/${machineid}`
}

export function openConsoleURL(machineid) {
    return `${SEVERNAMETWO}:8006/?console=kvm&novnc=1&vmid=${machineid}&vmname=uservm&node=pve&resize=off&cmd=`;
}

export function twoViewURL(location) {
    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox_old/getauthorization/location/${location}`;
    // return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmoxdc/getauthorization/location/${location}`;
    // return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/getauthorization/np`;
}

export function bareMetalViewURL(hostname) {
    // return `${SEVER_ILO}:${hostname}/json/login_session`;
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hpbaremetal/getilosession/ilourl/${hostname}`
}

export function GetBranchURL(clusterid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getclusterurl/clusterid/${clusterid}`;
    // return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getbranchurl/branchid/${location}`;
}


export function getUserOverviewURL(userid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getuseroverview/userid/${userid}`;
}

export function updateMachineURL(machineid, actionType) {

    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/updatemachinestage/machineid/${machineid}/statusid/${actionType}`;
    // return `${SEVERNAME}/apis/v4/unibase/platform/apps/savestatus/statusid/${actionType}/doctypeid/100011380000638/recordid/${machineid}`;
}

export function checkMachineStopURL(code, id) {
    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/gettaskstatusbyupid/upid/${code}/node/${id}`
}

export function deleteInstanceFailURL(orderid) {
    let { userId, sessionId } = getSessionAndUserId();
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/orderrefundamount/orderid/${orderid}/contactid/${userId}`;
    // return `http://192.168.88.99:1002/apis/v4/hyperfusion/hireserver/machines/updatemachineorderstage/orderid/${orderid}/orderstatus/4`
}

export function checkMachineURL(upid, node, location) {

    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmoxdc/gettaskstatusbyupid/upid/${upid}/node/${node}/location/${location}`
}

export function instanceConfigCreateMachine(machineid) {
    return `${SEVERNAME}/apis/v4/bizgaze/hyperfusion/servers/configmachine/machineid/${machineid}`;
}

export function getStatusByCode(code) {
    return `${SEVERNAME}/apis/v4/bizgaze/integrations/proxmox/getrrddata/vmid/${code}`;
}

// Auth
export function getSessionIdValidURL() {
    return `${SEVERNAME}/Account/Session/Validate`;
}

export function signOutURL() {
    return `${SEVERNAME}/Account/Domain`;
}

// user

export function getUserDetailsURL(phoneormail) {
    return `${SEVERNAME}/account/getuserbyphoneormail/${phoneormail}/${phoneormail}`
}

export function getUpdatePasswordURL() {
    return `${SEVERNAME}/account/UpdatePassword`;
    // return `${SEVERNAME}/Account/HypersfusionUpdatePassword`;
}

export function updateProfilePasswordURL() {
    return `${SEVERNAME}/hyperfusion/HypersfusionUpdatePassword`;
}

export function getThresholdsURL(machineid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getnotificationthresholds/machineid/${machineid}`
}

export function notifsConfigURL() {
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/savenotificationthreshold`
}

export function getBlockIdURL() {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getblocks`
}

export function getTimeLineDataURL(refid, installedappid) {
    return `${SEVERNAME}/apis/v4/unibase/notificationrule/platform/gettimelinelogs/refid/${refid}/installedappid/${installedappid}/ispriority/true`;
    // return `${SEVERNAME}/apis/v4/unibase/notificationrule/platform/gettimelinelogs/refid/${refid}`
}

export function postInstancesDescriptionURL() {
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/updatemachinedescription`;
}

export function getInstancesDescriptionURL(machineid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getmachinedescription/machineid/${machineid}`
}

export function createBackupDBURL() {
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/updatemachinedescription`;
}

export function getBackupDBListURL(machineid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/instancebackupslist/machineid/${machineid}`
}

export function postscriptmarketURL() {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/savescript`;
}

export function postSaveCronJobURL(scriptid) {
    return `${SCRIPTS_SERVERNAME}/savecronjob/${scriptid}`;
}

export function getScriptMarketListURL(contactid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getscripts/contactid/${contactid}`;
}

export function getSearchByScriptLogsNameURL(searchbyname, page = 1, scriptid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/searchscriptlogs/index/${page}/rows/${PAGE_INDEX_LIMIT}/scriptid/${scriptid}/searchbyname/${searchbyname}`;
}

export function postExecScriptURL(scriptid) {
    return `${SCRIPTS_SERVERNAME}/execmd/${scriptid}`;

    return `http://192.168.89.115:4000/execmd/${scriptid}`;
    // return `https://103.206.104.121:8443/execmd/${scriptid}`;

}

export function getAllScriptBymachineIdURL(machineid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getscriptsbymachineid/machineid/${machineid}`;
}

export function getDetailsScriptURL(scriptid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getscript/id/${scriptid}`
}

export function getScriptTableURL(userid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getscriptlog/userid/${userid}`;
}

export function getScriptOutputByIdURL(logid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getscriptsbyid/logid/${logid}`
    // return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getscriptsbyid/userid/${contactid}/scriptid/${scriptid}`;
    // return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getscriptsbyid/contactid/${contactid}/scriptid/${id}`;
}

export function getViewOutputMachineORScriptURL(page = 1, scriptid) {
    // return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getscriptlogsbymachineid/scriptid/${scriptid}`;
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getscriptlogsbymachineid/index/${page}/rows/${PAGE_INDEX_LIMIT}/scriptid/${scriptid}`;
}

export function ClearAllNotificationURL() {
    return `${SEVERNAME}/apis/v4/unibase/reminders/platform/ChangeReminderStatusAsRead/np`;
}

export function getScriptsByParentIdURL(parentid) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getscriptbyparentid/parentid/${parentid}`;
}
export function getLogOutputByIdLogURL(logId) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getlogoutputbyid/logid/${logId}`;
}

export function getLogOutputByIdLog_Page_URL(logid, rows = 1) {
    return `${SEVERNAME}/apis/v4/hireserver/integrations/hireserverinternal/getlogoutputbyidpage/index/${rows}/rows/5/logid/${logid}`
}

// clusters

export function postClusterCreateReplicaOrderURL() {
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/createreplicaorder`;
}

export function postServeravailAbilityClustersURL() {
    return `${SEVERNAME}/apis/v4/hyperfusion/hireserver/machines/getreplicaserveravailability`;
}